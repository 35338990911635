import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _get from "lodash/get";
import { Button, Col, Dropdown, Menu, Row, Typography } from "antd";
import { TransactionColumn } from "components/Columns";
import Table from "components/common/Table";

import { getTransactionDetailRequest } from "providers/TransactionProvider/slice";
import { EllipsisOutlined, LeftOutlined } from "@ant-design/icons";

const { Title } = Typography;

const UserTransactionDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const transactionDetail = useSelector((state) =>
    _get(state, "transactionSlice.transactionDetail")
  );

  const isLoading = useSelector((state) =>
    _get(state, "transactionSlice.isLoading")
  );

  useEffect(() => {
    if (id) {
      dispatch(getTransactionDetailRequest({ userId: id }));
    }
  }, [dispatch, id]);
  const menu = (record) => (
    <Menu>
      <Menu.Item
        key="view"
        onClick={() => navigate(`/fonehouse/billing_list/${record.order?._id}`)}
      >
        Xem đơn hàng chi tiết
      </Menu.Item>
    </Menu>
  );
  const actionButtons = (record) => (
    <Dropdown
      overlay={
        record.type === "order_reward" || record.type === "order_reward_reverse"
          ? menu(record)
          : ""
      }
      trigger={["click"]}
    >
      <Button
        className="see-more-btn"
        type="ghost"
        icon={<EllipsisOutlined />}
      />
    </Dropdown>
  );
  const columns = [
    TransactionColumn.objectId,
    TransactionColumn.name,
    TransactionColumn.email,
    TransactionColumn.phone,
    TransactionColumn.amount,
    TransactionColumn.type,
    TransactionColumn.status,
    TransactionColumn.createdAt,
    TransactionColumn.finishedAt,
    TransactionColumn.duration,

    TransactionColumn.orderId,
    TransactionColumn.orderTotalPrice,
    TransactionColumn.orderCreatedAt,
    {
      title: "Thao tác",
      render: (record) => actionButtons(record),
    },
  ];

  return (
    <div className="transaction-detail-wrapper">
      <div>
        <Col>
          <Typography.Title level={4}>Chi tiết giao dịch</Typography.Title>
          <Button
            style={{ width: 200 }}
            icon={<LeftOutlined />}
            onClick={() => navigate(-1)}
          >
            Quay lại
          </Button>
        </Col>
      </div>
      <div className="content-container">
        <Table
          scroll={{ x: 1000 }}
          loading={isLoading}
          bordered
          columns={columns}
          dataSource={transactionDetail}
          rowKey={(data) => data._id}
          // total={transactionTotal}
          // onChange={handleTableChange}
        />
      </div>
    </div>
  );
};

export default UserTransactionDetail;
