import { call, put, takeLatest } from "redux-saga/effects";
import _pick from "lodash/pick";
import request from "utils/api";
import {
  getAllTransactionsRequest,
  getAllTransactionsSuccess,
  getAllTransactionsFailure,
  getTransactionDetailRequest,
  getTransactionDetailSuccess,
  getTransactionDetailFailure,
} from "./slice";

function* getAllTransactions(action) {
  try {
    const params = _pick(action.payload, [
      "page",
      "limit",
      "keyword",
      "status",
      "subType",
    ]);

    const { data } = yield call(request, "api/reward/transactions", params, {
      method: "GET",
    });

    yield put(getAllTransactionsSuccess(data));
  } catch (error) {
    yield put(getAllTransactionsFailure(error));
  }
}

function* getTransactionDetail(action) {
  try {
    const params = _pick(action.payload, ["page", "limit", "userId"]);

    const { data } = yield call(request, "api/reward/transactions", params, {
      method: "GET",
    });

    yield put(getTransactionDetailSuccess(data));
  } catch (error) {
    yield put(getTransactionDetailFailure(error));
  }
}

export default function* transactionSaga() {
  yield takeLatest(getAllTransactionsRequest.type, getAllTransactions);
  yield takeLatest(getTransactionDetailRequest.type, getTransactionDetail);
}
