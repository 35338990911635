import { createSlice } from "@reduxjs/toolkit";

const TransactionSlice = createSlice({
  name: "transaction",
  initialState: {
    isLoading: false,
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    nextPage: null,
    page: 1,
    pagingCounter: 1,
    prevPage: null,
    totalDocs: 0,
    totalPages: 1,
    selectedRowKeys: [],
    transactionDetail: [],
  },
  reducers: {
    getAllTransactionsRequest(state) {
      return { ...state, isLoading: true };
    },
    getAllTransactionsSuccess(state, action) {
      const { payload = {} } = action;
      const { docs, totalDocs } = payload;
      console.log(payload, "payload");
      return {
        ...state,
        isLoading: false,
        docs,
        totalDocs,
      };
    },
    getAllTransactionsFailure(state) {
      return { ...state, isLoading: false };
    },
    getTransactionDetailRequest(state) {
      return { ...state, isLoading: true };
    },
    getTransactionDetailSuccess(state, action) {
      const { payload = {} } = action;
      const { docs, totalDocs } = payload;
      console.log(payload, "payload");
      return {
        ...state,
        isLoading: false,
        transactionDetail: docs,
        totalDocs,
      };
    },
    getTransactionDetailFailure(state) {
      return { ...state, isLoading: false };
    },
    stopLoading(state) {
      return { ...state, isLoading: false };
    },
    setSelectedIds(state, action) {
      const { record, selected, selectedRows, changeRows } = action.payload;

      if (record) {
        // Single selection
        const newSelectedKeys = selected
          ? [...state.selectedRowKeys, record.key]
          : state.selectedRowKeys.filter((key) => key !== record.key);
        return { ...state, selectedRowKeys: newSelectedKeys };
      }
      // Bulk selection
      const newSelectedKeys = selected
        ? [
            ...new Set([
              ...state.selectedRowKeys,
              ...selectedRows.map((row) => row.key),
            ]),
          ]
        : state.selectedRowKeys.filter(
            (key) => !changeRows.find((row) => row.key === key)
          );
      return { ...state, selectedRowKeys: newSelectedKeys };
    },
  },
});

export const {
  getAllTransactionsRequest,
  getAllTransactionsSuccess,
  getAllTransactionsFailure,
  getTransactionDetailRequest,
  getTransactionDetailSuccess,
  getTransactionDetailFailure,
  stopLoading,
  setSelectedIds,
} = TransactionSlice.actions;

export default TransactionSlice.reducer;
