import { Link, useNavigate } from "react-router-dom";
import i18n from "i18n";
// import { useNavigate } from 'react-router-dom';
import {
  Row,
  Typography,
  Button,
  Menu,
  Dropdown,
  Tabs,
  // Col
} from "antd";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";

import { UserColumn } from "components/Columns";
import TabHeader from "components/TabHeader";
import Table from "components/common/Table";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "hooks";
import { IconSeeMore } from "assets/images";
import {
  getAllUserRequest,
  setSelectedIds,
  updateAccuracyRequest,
  setDecentralizationPopup,
  blockUserRequest,
  unblockUserRequest,
} from "providers/AdminProvider/slice";
import { getRewardSystemBalanceRequest } from "providers/RewardPointManager/slice";
import { useEffect } from "react";
import SearchBar from "./SearchBar";
import DecentralizationModal from "./DecentralizationModal";

import "./styles.less";

const { TabPane } = Tabs;

const UserList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const agentList = useSelector((state) => _get(state, "admin.docs"));
  const agentTotal = useSelector((state) => _get(state, "admin.totalDocs"));
  const rewardSystemBalance = useSelector((state) =>
    _get(state, "rewardPointManagerSlice.rewardSystemBalance")
  );

  const isLoading = useSelector((state) => _get(state, "admin.isLoading"));
  const selectedRowKeys = useSelector((state) =>
    _get(state, "admin.selectedRowKeys")
  );
  const [search, setSearch] = useQuery(getAllUserRequest, {
    arrayParams: ["roles"],
  });
  const selectedRows = useSelector((state) =>
    _get(state, "admin.selectedRows", [])
  );
  const isDisableAuthBtn = !!selectedRows.find((item) => item.accuracy);

  useEffect(() => {
    dispatch(getRewardSystemBalanceRequest());
  }, [dispatch]);

  const blockUser = (idUser) => {
    dispatch(blockUserRequest({ idUser })).then(() =>
      dispatch(getAllUserRequest(search))
    );
  };

  const unblockUser = (idUser) => {
    dispatch(unblockUserRequest({ idUser })).then(() =>
      dispatch(getAllUserRequest(search))
    );
  };

  const updateAccuracy = (idUser, value) => {
    dispatch(updateAccuracyRequest({ idUser, accuracy: value })).then(() =>
      dispatch(getAllUserRequest(search))
    );
  };

  const handleClickMenu = (data) => {
    const { values, record } = data;

    if (values.key === "decentralization") {
      dispatch(
        setDecentralizationPopup({
          isOpen: true,
          data: record,
          form: "decentralizationForm",
        })
      );
    }
    if (values.key === "userTransaction") {
      navigate(`/user_transactions/${record.id}`);
    }
    if (values.key === "decentralizationUser") {
      dispatch(
        setDecentralizationPopup({
          isOpen: true,
          data: record,
          form: "decentralizationUserForm",
        })
      );
    }
    if (values.key === "blockUser") {
      blockUser(record._id);
    }
    if (values.key === "unblockUser") {
      unblockUser(record._id);
    }
  };
  const menu = (record) => {
    const { _id, blocked } = record;
    return (
      <Menu onClick={(values) => handleClickMenu({ values, record })}>
        <Menu.Item key="userInfo">
          <Link to={`/home/${_id}`} state={{ canGoBack: true }}>
            {i18n.t("menuUserInfo.viewInformation")}
          </Link>
        </Menu.Item>
        {!record.accuracy ? (
          <Menu.Item key="accuracy" onClick={() => updateAccuracy([_id], true)}>
            {i18n.t("menuUserInfo.accuracy")}
          </Menu.Item>
        ) : (
          <Menu.Item
            key="accuracy"
            onClick={() => updateAccuracy([_id], false)}
          >
            {i18n.t("menuUserInfo.unAccuracy")}
          </Menu.Item>
        )}
        <Menu.Item key="decentralization">
          {i18n.t("menuUserInfo.decentralization")}
        </Menu.Item>
        <Menu.Item key="decentralizationUser">
          {i18n.t("menuUserInfo.decentralizationUser")}
        </Menu.Item>
        <Menu.Item key="userTransaction">Xem lịch sử giao dịch</Menu.Item>
        <Menu.Item key={blocked ? "unblockUser" : "blockUser"}>
          <Typography.Text type="danger">
            {i18n.t(
              blocked ? "menuUserInfo.unblockUser" : "menuUserInfo.blockUser"
            )}
          </Typography.Text>
        </Menu.Item>
      </Menu>
    );
  };

  const actionButtons = (record) => (
    <Dropdown overlay={() => menu(record)} placement="bottomRight" arrow>
      <Button
        className="see-more-btn"
        type="ghost"
        icon={<img src={IconSeeMore} alt="see-more" />}
      />
    </Dropdown>
  );

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, _selectedRows) => {
      dispatch(
        setSelectedIds({ record, selected, selectedRows: _selectedRows })
      );
    },
    onSelectAll: (selected, _selectedRows, changeRows) => {
      dispatch(
        setSelectedIds({ selected, selectedRows: _selectedRows, changeRows })
      );
    },
  };

  const columns = [
    UserColumn.objectId,
    UserColumn.phone,
    UserColumn.displayName,
    UserColumn.email,
    UserColumn.permission,
    UserColumn.auth,
    UserColumn.rewardPoint,
    {
      title: (
        <Dropdown
          overlay={() => (
            <Menu>
              <Menu.Item
                disabled={_isEmpty(selectedRowKeys) || isDisableAuthBtn}
                onClick={() => updateAccuracy(selectedRowKeys)}
              >
                {i18n.t("menuUserInfo.accuracy")}
              </Menu.Item>
            </Menu>
          )}
          placement="bottomRight"
          arrow
        >
          <Button
            className="see-more-btn"
            type="ghost"
            icon={<img src={IconSeeMore} alt="see-more" />}
          />
        </Dropdown>
      ),
      render: (record) => actionButtons(record),
    },
  ];

  return (
    <div className="cms-admin-list-wrapper">
      <DecentralizationModal />
      <div className="cms-admin-list-header">
        <Typography.Title level={4} style={{ marginRight: 24 }}>
          Danh sách User
        </Typography.Title>
        <Typography.Title
          style={{
            color: "#000",
          }}
          level={4}
        >
          Vcoin đã phân phối: {rewardSystemBalance?.toLocaleString()}
        </Typography.Title>
      </div>
      <div className="content-container">
        <SearchBar />
        <Tabs
          className="superMarket-tabs-container"
          activeKey={search.mine}
          type="card"
          onChange={(key) => {
            setSearch(
              {
                blocked: key,
                keyword: search.keyword,
                status: search.status,
                activeStatus: search.activeStatus,
              },
              true
            );
          }}
        >
          {["", true].map((value) => (
            <TabPane
              className="status-tab-pane"
              tab={
                <TabHeader
                  headerTitle={{ "": "Tất cả", true: "Đã khoá" }}
                  status={value}
                  total={100}
                />
              }
              key={value}
            >
              <Table
                rowSelection={rowSelection}
                scroll={{ x: 1000 }}
                loading={isLoading}
                bordered
                columns={columns}
                dataSource={agentList}
                rowKey={(data) => data._id}
                total={agentTotal}
                onRow={(record) => ({
                  onDoubleClick: () => {
                    navigate(`/home/${record._id}`, {
                      state: { canGoBack: true },
                    });
                  },
                })}
              />
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default UserList;
