import { put, takeLeading, call } from "redux-saga/effects";
import request from "utils/api";
import Helper from "utils/helpers";
import _pick from "lodash/pick";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { safe } from "providers/GeneralProvider/saga";
import {
  getAllUserRequest,
  getAllUserSuccess,
  updateRolesRequest,
  updateRolesSuccess,
  stopLoading,
  updateAccuracyRequest,
  updateAccuracySuccess,
  setDecentralizationPopup,
  updateUserRoleRequest,
  updateUserRoleSuccess,
  blockUserRequest,
  blockUserSuccess,
  unblockUserRequest,
  unblockUserSuccess,
} from "providers/AdminProvider/slice";

function* getAllUser(action) {
  const params = _pick(action.payload, [
    "page",
    "limit",
    "keyword",
    "roles",
    "sort",
    "sortName",
    "sortEmail",
    "sortAccuracy",
    "blocked",
  ]);
  params.sortReward = -1;
  if (!_isEmpty(params.keyword)) {
    params.keyword =
      Helper.sortStringWithoutSpecialChars(params.keyword) || undefined;
  }
  const { data } = yield call(
    request,
    "api/admin/userManager/getAllUser",
    params,
    { method: "GET" }
  );
  yield put(getAllUserSuccess(data));
}

function* updateAccuracy(action) {
  const params = _pick(action.payload, ["idUser", "accuracy"]);
  const { meta } = action;
  const { data } = yield call(
    request,
    "/api/admin/userManager/updateAccuracy",
    params,
    { method: "PUT" }
  );
  yield put(updateAccuracySuccess(data, meta));
  Helper.toastr(
    "",
    params.accuracy ? "Xác thực thành công" : "Hủy xác thực thành công",
    "success"
  );
}
function* updateRole(action) {
  const params = _pick(action.payload, ["idUser", "roles"]);
  const { data } = yield call(
    request,
    "api/admin/userManager/updateRole",
    params,
    { method: "PUT" }
  );
  yield put(
    updateRolesSuccess({
      idUser: params.idUser,
      data,
      docs: _get(action, "payload.docs"),
    })
  );
  yield put(setDecentralizationPopup({ isOpen: false }));
  Helper.toastr(`User ID:${params.idUser}`, "Phân quyền thành công", "success");
}

function* updateUserRole(action) {
  const params = _pick(action.payload, ["idUser", "role"]);
  const idUser = _get(action, "payload.idUser");
  yield call(request, "api/admin/userManager/updateUserRole", params, {
    method: "PUT",
  });
  yield put(
    updateUserRoleSuccess({
      idUser,
      docs: _get(action, "payload.docs"),
      totalDocs: _get(action, "payload.totalDocs"),
    })
  );
  yield put(setDecentralizationPopup({ isOpen: false }));
  Helper.toastr(
    `User ID: ${params.idUser}`,
    "Phân quyền user thành công",
    "success"
  );
}

function* blockUser(action) {
  const params = _pick(action.payload, ["idUser"]);
  const { meta } = action;
  const { data } = yield call(
    request,
    `/api/admin/userManager/block-user/${params.idUser}`,
    params,
    { method: "POST" }
  );
  yield put(blockUserSuccess(data, meta));
  Helper.toastr("Thành công", "Khoá tài khoản thành công", "success");
}

function* unblockUser(action) {
  const params = _pick(action.payload, ["idUser"]);
  const { meta } = action;
  const { data } = yield call(
    request,
    `/api/admin/userManager/un-block-user/${params.idUser}`,
    params,
    { method: "POST" }
  );
  yield put(unblockUserSuccess(data, meta));
  Helper.toastr("Thành công", "Mở khoá tài khoản thành công", "success");
}

function* onError() {
  yield put(stopLoading());
}

// function * onGetCurrentUserError () {
//   yield put(stopLoading());
//   Helper.removeAuthToken();
// }

export default function* watchAuth() {
  yield takeLeading(getAllUserRequest.type, safe(getAllUser, onError));
  yield takeLeading(updateAccuracyRequest.type, safe(updateAccuracy, onError));
  yield takeLeading(updateRolesRequest.type, safe(updateRole, onError));
  yield takeLeading(updateUserRoleRequest.type, safe(updateUserRole, onError));
  yield takeLeading(blockUserRequest.type, safe(blockUser, onError));
  yield takeLeading(unblockUserRequest.type, safe(unblockUser, onError));
}
