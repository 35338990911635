/* eslint-disable react/display-name */

import { Row, Col, Tooltip } from "antd";
// import _get from 'lodash/get';
import _isEmpty from "lodash/isEmpty";
import TypeBlock from "components/TypeBlock";
import PermissionTooltip from "components/PermissionTooltip";

import Helper from "utils/helpers";
import i18n from "i18n";
import { IconChecked } from "assets/images";

const Columns = {};

const renderDataValue = (value) => {
  const style = value ? {} : { color: "#c0c0c0" };
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};

Columns.objectId = {
  dataIndex: "_id",
  key: "_id",
  title: "#",
  width: 195,
  render: (_id) => renderDataValue(_id),
};

Columns.phone = {
  dataIndex: "phone",
  key: "phone",
  title: "Số điện thoại",
  width: 120,
  render: (phone) => renderDataValue(phone),
};

Columns.displayName = {
  title: "Tên hiển thị",
  dataIndex: "name",
  key: "name",
  sorter: true,
  render: (name) => renderDataValue(name),
};

Columns.permission = {
  title: "Phân quyền",
  dataIndex: "roles",
  key: "roles",
  render: (roles = []) =>
    renderDataValue(
      !_isEmpty(roles) ? (
        <Row>
          {Helper.sortUserPermission(roles).map((role) => (
            <Tooltip
              key={role}
              title={<PermissionTooltip active={role} />}
              color="white"
            >
              <Col style={{ marginRight: 4 }}>
                <TypeBlock type={role} />
              </Col>
            </Tooltip>
          ))}
        </Row>
      ) : null
    ),
};

Columns.auth = {
  title: "Xác thực",
  dataIndex: "accuracy",
  key: "accuracy",
  sorter: true,
  render: (accuracy) => renderDataValue(accuracy ? <IconChecked /> : <div />),
};
Columns.rewardPoint = {
  title: "Vcoin",
  dataIndex: "rewardBalance",
  key: "rewardBalance",
  sorter: true,
  render: (rewardBalance) =>
    renderDataValue((rewardBalance || 0).toLocaleString()),
};
Columns.email = {
  title: "Email",
  dataIndex: "email",
  key: "email",
  sorter: true,
  render: (email) => renderDataValue(email),
};
export default Columns;
