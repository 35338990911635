import { memo } from "react";
import { SearchOutlined } from "@ant-design/icons";

import SearchBarComponent from "components/SearchBar";
import { TextInput, Filter, Select } from "components/common/Form";
import Helper from "utils/helpers";
import { SIM_SEARCH_PARAM } from "utils/constants";

const statusOptions = [
  {
    value: "BEING_TRANSPORTED",
    label: "Đã gửi hàng",
  },
  {
    value: "CANCELLED",
    label: "Đã huỷ",
  },
  {
    value: "NEW",
    label: "Đơn hàng mới",
  },
  {
    value: "DELIVERED",
    label: "Đã giao",
  },
];

const SearchBar = ({ type, searchObject }) => (
  <SearchBarComponent
    filterFields={[
      "keyword",
      "status",
      "searchParams",
      "phone",
      "customOrderId",
    ]}
    isOnChangeSearch
  >
    <>
      <TextInput
        size="middle"
        prefix={<SearchOutlined />}
        name={searchObject.searchParams ?? "keyword"}
        allowClear={false}
      />
      <Select
        name="searchParams"
        placeholder="Tìm kiếm theo..."
        options={Helper.convertObjectToOptions(SIM_SEARCH_PARAM, true, "Tên")}
      />
      {type === "REQUESTED" && (
        <Filter
          name="status"
          options={statusOptions}
          popConfirmClassName="gb-role-popconfirm"
        />
      )}
    </>
  </SearchBarComponent>
);

export default memo(SearchBar);
