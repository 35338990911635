/* eslint-disable react/display-name */
import i18n from "i18n";

const Columns = {};

const renderDataValue = (value) => {
  const style = value ? {} : { color: "#c0c0c0" };
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};

Columns.objectId = {
  dataIndex: "_id",
  key: "_id",
  title: "#",
  width: 195,
  render: (_id) => renderDataValue(_id),
};

Columns.type = {
  dataIndex: "type",
  key: "type",
  title: "Loại hình",
  width: 120,
  render: (type) => {
    const typeMap = {
      transfer_to_store: "Chuyển điểm cho cửa hàng",
      transfer_to_user: "Chuyển điểm cho người dùng",
      order_reward: "Đặt hàng phần thưởng",
      order_reward_reverse: "Hoàn trả phần thưởng",
      use_reward: "Sử dụng phần thưởng",
      use_reward_reverse: "Hoàn trả phần thưởng đã sử dụng",
      withdraw_via_viego: "Rút tiền qua Viego",
    };
    const colorMap = {
      transfer_to_store: "#1890ff",
      transfer_to_user: "#52c41a",
      order_reward: "#faad14",
      order_reward_reverse: "#ff4d4f",
      use_reward: "#722ed1",
      use_reward_reverse: "#eb2f96",
      withdraw_via_viego: "#fa8c16",
    };
    return renderDataValue(
      <span style={{ color: colorMap[type], fontWeight: "bolder" }}>
        {typeMap[type] || type}
      </span>
    );
  },
};

Columns.status = {
  title: "Trạng thái",
  dataIndex: "status",
  key: "status",
  sorter: true,
  render: (status) => renderDataValue(status),
};
Columns.status = {
  title: "Trạng thái",
  dataIndex: "status",
  key: "status",
  sorter: true,
  render: (status) => renderDataValue(status),
};
Columns.amount = {
  title: "Số lượng",
  dataIndex: "amount",
  key: "amount",
  sorter: true,
  render: (amount, record) => {
    const isPositive = record.type === "order_reward";
    const prefix = isPositive ? "+" : "-";
    const color = isPositive ? "#52c41a" : "#ff4d4f";
    return renderDataValue(
      <span style={{ color, fontWeight: "bold" }}>{`${prefix}${amount}`}</span>
    );
  },
};

Columns.createdAt = {
  title: "Tạo lúc",
  dataIndex: "createdAt",
  key: "createdAt",
  sorter: true,
  render: (createdAt) => {
    const date = new Date(createdAt);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const formattedTime = date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    return renderDataValue(`${formattedDate} ${formattedTime}`);
  },
};

Columns.finishedAt = {
  title: "Hoàn thành lúc",
  dataIndex: "finishedAt",
  key: "finishedAt",
  sorter: true,
  render: (finishedAt) => {
    const date = new Date(finishedAt);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const formattedTime = date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    return renderDataValue(`${formattedDate} ${formattedTime}`);
  },
};
Columns.duration = {
  title: "Thời gian xử lý",
  dataIndex: "finishedAt",
  key: "duration",
  sorter: true,
  render: (finishedAt, record) => {
    if (!finishedAt) return renderDataValue("-");

    const start = new Date(record.createdAt);
    const end = new Date(finishedAt);
    const diffInMs = end - start;
    const diffInSecs = Math.round(diffInMs / 1000);
    if (diffInSecs < 60) {
      return renderDataValue(`${diffInSecs} giây trước`);
    }

    const diffInMins = Math.floor(diffInSecs / 60);
    if (diffInMins < 60) {
      return renderDataValue(`${diffInMins} phút trước`);
    }

    const diffInHours = Math.floor(diffInMins / 60);
    if (diffInHours < 24) {
      return renderDataValue(`${diffInHours} giờ trước`);
    }

    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 30) {
      return renderDataValue(`${diffInDays} ngày trước`);
    }

    const diffInMonths = Math.floor(diffInDays / 30);
    if (diffInMonths < 12) {
      return renderDataValue(`${diffInMonths} tháng trước`);
    }

    const diffInYears = Math.floor(diffInMonths / 12);
    return renderDataValue(`${diffInYears} năm trước`);
  },
};

Columns.content = {
  title: "Nội dung GD",
  dataIndex: "content",
  key: "content",
  sorter: true,
  render: (content) => renderDataValue(content),
};
Columns.name = {
  title: "Username",
  dataIndex: "owner",
  key: "owner",
  sorter: true,
  render: (owner) => renderDataValue(owner.name),
};
Columns.email = {
  title: "email",
  dataIndex: "owner",
  key: "owner",
  sorter: true,
  render: (owner) => renderDataValue(owner.email),
};
Columns.phone = {
  title: "SDT",
  dataIndex: "owner",
  key: "owner",
  sorter: true,
  render: (owner) => renderDataValue(owner.phone),
};
Columns.orderId = {
  title: "Mã đơn hàng",
  dataIndex: "order",
  key: "orderId",
  sorter: true,
  render: (order) => renderDataValue(order?.orderId),
};
Columns.orderCreatedAt = {
  title: "Ngày tạo đơn",
  dataIndex: "order",
  key: "orderCreatedAt",
  sorter: true,
  render: (order) => {
    if (!order?.createdAt) return renderDataValue("-");
    const date = new Date(order.createdAt);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const formattedTime = date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    return renderDataValue(`${formattedDate} ${formattedTime}`);
  },
};
Columns.orderTotalPrice = {
  title: "Tổng tiền (JPY)",
  dataIndex: "order",
  key: "totalPrice",
  sorter: true,
  render: (order) => renderDataValue(order?.totalPrice || "-"),
};

export default Columns;
