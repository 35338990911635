import { createSlice } from "@reduxjs/toolkit";

const rewardPointManagerSlice = createSlice({
  name: "rewardPointManager",
  initialState: {
    isLoading: false,
    rewardPointList: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    nextPage: null,
    page: 1,
    rewardPointTabType: "POINT_PERSONAL_MANAGE",
    totalDocs: 0,
    personalPoints: 0,
    storePoints: [],
    rewardSystemBalance: 0,
  },
  reducers: {
    getRewardSystemBalanceRequest(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getRewardSystemBalanceSuccess(state, action) {
      console.log(action.payload, "action.payload");
      return {
        ...state,
        rewardSystemBalance: action.payload,
        isLoading: false,
      };
    },
    getRewardPointListRequest(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getRewardPointListSuccess(state, action) {
      const { docs, totalDocs } = action.payload.data;
      const type = action.payload?.type;
      return {
        ...state,
        rewardPointList: docs,
        totalDocs,
        rewardPointTabType: type,
        isLoading: false,
      };
    },
    exchangePointsToUserRequest(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    setRewardPointTab(state, action) {
      state.rewardPointTabType = action.payload.rewardPointTabType;
      return state;
    },
    getRewardBalanceRequest(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getRewardBalanceSuccess(state, action) {
      const personalPoints =
        action.payload?.data?.find((item) => item.type === "user")?.account
          ?.balance ?? 0;
      const storePoints =
        action.payload?.data?.filter((item) => item.type === "store") ?? [];
      return {
        ...state,
        personalPoints,
        storePoints,
        isLoading: false,
      };
    },
    requestFail(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const {
  getRewardSystemBalanceRequest,
  getRewardSystemBalanceSuccess,
  getRewardPointListRequest,
  getRewardPointListSuccess,
  requestFail,
  setRewardPointTab,
  exchangePointsToUserRequest,
  getRewardBalanceRequest,
  getRewardBalanceSuccess,
} = rewardPointManagerSlice.actions;

export default rewardPointManagerSlice.reducer;
