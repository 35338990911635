import { Link, useNavigate } from "react-router-dom";
import i18n from "i18n";
import { Row, Typography, Button, Menu, Dropdown, Tabs } from "antd";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { EllipsisOutlined } from "@ant-design/icons";

import { TransactionColumn } from "components/Columns";
import TabHeader from "components/TabHeader";
import Table from "components/common/Table";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "hooks";
import { IconSeeMore } from "assets/images";
import {
  getAllTransactionsRequest,
  setSelectedIds,
} from "providers/TransactionProvider/slice";
import { useEffect } from "react";
import "./styles.less";
import { getRewardSystemBalanceRequest } from "providers/RewardPointManager/slice";
import SearchBar from "./SearchBar";

const { TabPane } = Tabs;

const UserTransactions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const transactionList = useSelector((state) =>
    _get(state, "transactionSlice.docs")
  );

  const transactionTotal = useSelector((state) =>
    _get(state, "transactionSlice.totalDocs")
  );
  const rewardSystemBalance = useSelector((state) =>
    _get(state, "rewardPointManagerSlice.rewardSystemBalance")
  );
  const isLoading = useSelector((state) =>
    _get(state, "transactionSlice.isLoading")
  );
  const selectedRowKeys = useSelector((state) =>
    _get(state, "transactionSlice.selectedRowKeys")
  );
  useEffect(() => {
    dispatch(getRewardSystemBalanceRequest());
  }, [dispatch]);
  const [search, setSearch] = useQuery(getAllTransactionsRequest);

  const menu = (record) => (
    <Menu>
      {(search.subType === "order_reward" ||
        search.subType === "order_reward_reverse") && (
        <Menu.Item
          key="view"
          onClick={() =>
            navigate(`/fonehouse/billing_list/${record.order?._id}`)
          }
        >
          Xem đơn hàng chi tiết
        </Menu.Item>
      )}

      <Menu.Item
        key="history"
        onClick={() => navigate(`/user_transactions/${record.owner.id}`)}
      >
        Lịch sử giao dịch
      </Menu.Item>
    </Menu>
  );

  const actionButtons = (record) => (
    <Dropdown overlay={menu(record)} trigger={["click"]}>
      <Button
        className="see-more-btn"
        type="ghost"
        icon={<EllipsisOutlined />}
      />
    </Dropdown>
  );

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, _selectedRows) => {
      dispatch(
        setSelectedIds({ record, selected, selectedRows: _selectedRows })
      );
    },
    onSelectAll: (selected, _selectedRows, changeRows) => {
      dispatch(
        setSelectedIds({ selected, selectedRows: _selectedRows, changeRows })
      );
    },
  };

  const columns = [
    TransactionColumn.objectId,
    TransactionColumn.name,
    TransactionColumn.email,
    TransactionColumn.phone,
    TransactionColumn.amount,
    TransactionColumn.type,
    TransactionColumn.status,
    ...(search.subType === "order_reward" ||
    search.subType === "order_reward_reverse"
      ? [
          TransactionColumn.orderId,
          TransactionColumn.orderTotalPrice,
          TransactionColumn.orderCreatedAt,
        ]
      : []),
    TransactionColumn.createdAt,
    TransactionColumn.finishedAt,
    TransactionColumn.duration,
    {
      title: "Thao tác",
      render: (record) => actionButtons(record),
    },
  ];

  return (
    <div className="transaction-list-wrapper">
      <div className="transaction-list-header">
        <Typography.Title level={4}>
          Quản lý giao dịch điểm trên toàn hệ thống
        </Typography.Title>
        <Typography.Title
          style={{
            color: "#000",
          }}
          level={4}
        >
          Vcoin đã phân phối: {rewardSystemBalance?.toLocaleString()}
        </Typography.Title>
      </div>
      <div className="content-container">
        <Tabs
          className="transaction-tabs-container"
          activeKey={search.type}
          type="card"
          style={{ marginTop: 12 }}
          onChange={(key) => {
            console.log(key, "key");
            setSearch(
              {
                subType: key,
              },
              true
            );
          }}
        >
          {[
            "",
            "transfer_to_store",
            "transfer_to_user",
            "order_reward",
            "order_reward_reverse",
            "use_reward",
            "use_reward_reverse",
            "withdraw_via_viego",
          ].map((value) => (
            <TabPane
              className="status-tab-pane"
              tab={<TabHeader status={value} />}
              key={value}
            >
              <Table
                rowSelection={rowSelection}
                scroll={{ x: 1000 }}
                loading={isLoading}
                bordered
                columns={columns}
                dataSource={transactionList}
                rowKey={(data) => data._id}
                total={transactionTotal}
                onRow={(record) => ({
                  onDoubleClick: () =>
                    navigate(`/user_transactions/${record.owner.id}`),
                })}
              />
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default UserTransactions;
