import { all } from "redux-saga/effects";
import watchAuth from "providers/AuthProvider/saga";
import watchPost from "providers/PostProvider/saga";
import watchAdmin from "providers/AdminProvider/saga";
import watchNews from "providers/NewsProvider/saga";
import watchVisa from "providers/VisaProvider/saga";
import watchProduct from "providers/ProductProvider/saga";
import watchBusiness from "providers/BusinessProvider/saga";
import watchNewsFonehouse from "providers/NewsFonehouseProvider/saga";
import watchMarketProduct from "providers/MarketProductProvider/saga";
import watchRestaurantProduct from "providers/RestaurantProductProvider/saga";
import watchAdminManager from "providers/AdminManagerProvider/saga";
import watchBillingManager from "providers/BillingProvider/saga";
import watchBanner from "providers/BannerProvider/saga";
import watchGuide from "providers/GuideFonehouseProvider/saga";
import watchStatistical from "providers/FonehouseStatistical/saga";
import watchMoneyManagement from "providers/FonehouseMoneyManagement/saga";
import watchHistoryEdit from "providers/FonehouseHistoryEdit/saga";
import watchMessenger from "providers/MessengerProvider/saga";
import watchNotification from "providers/NotificationProvider/saga";
import watchEntertainmentProduct from "providers/EntertainmentProductProvider/saga";
import watchCosmeticProduct from "providers/CosmeticProductProvider/saga";
import watchSupport from "providers/SupportProvider/saga";
import watchReport from "providers/ReportManagerProvider/saga";
import watchRating from "providers/CommentAndRatingProvider/saga";
import watchStore from "providers/StoreProvider/saga";
import watchSim from "providers/SimProvider/saga";
import watchIdentify from "providers/IdentifyUserProvider/saga";
import watchRewardPoint from "providers/RewardPointManager/saga";
import watchAccessory from "providers/AccessoryProvider/saga";
import watchLiquidWithViego from "providers/LiquidWithViegoProvider/saga";
import watchTransaction from "providers/TransactionProvider/saga";

export default function* rootSaga() {
  yield all([
    watchAuth(),
    watchPost(),
    watchAdmin(),
    watchNews(),
    watchProduct(),
    watchBusiness(),
    watchNewsFonehouse(),
    watchMarketProduct(),
    watchRestaurantProduct(),
    watchAdminManager(),
    watchBillingManager(),
    watchBanner(),
    watchGuide(),
    watchStatistical(),
    watchMoneyManagement(),
    watchHistoryEdit(),
    watchMessenger(),
    watchNotification(),
    watchEntertainmentProduct(),
    watchCosmeticProduct(),
    watchSupport(),
    watchReport(),
    watchRating(),
    watchStore(),
    watchSim(),
    watchVisa(),
    watchIdentify(),
    watchRewardPoint(),
    watchAccessory(),
    watchLiquidWithViego(),
    watchTransaction(),
  ]);
}
